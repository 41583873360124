<template>
  <div
    class="shadow-sm rounded border research-card my-2"
    v-if="research != null"
  >
    <div
      class="rounded border-start border-10 p-3 pe-5"
      :class="{
        'border-primary':
          research.status.name != 'Closed' &&
          research.status.name != 'Rejected' &&
          research.status.name != 'Suspended',
        'border-danger': research.status.name == 'Rejected',
        'border-success': research.status.name == 'Closed',
        'border-warning': research.status.name == 'Suspended',
      }"
    >
      <!-- card header -->
      <div
        class="reseach-card-header d-block"
        @click.prevent="goToResearch(research.id)"
      >
        <h6
          @mouseover="showHideFullTitle"
          @mouseleave="showHideFullTitle"
          class="m-0 overflow-hidden research-title text-nowrap"
        >
          {{ research.title }}
        </h6>
        <small class="mt-1"
          ><svg
            class="d-inline"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
              fill="#18446d"
            ></path>
            <path
              d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
              fill="#676767"
            ></path>
          </svg>
          by {{ research.creator.full_name }}</small
        >
      </div>
      <!-- card body -->
      <div class="reseach-card-body mt-3">
        <!-- limu id badge -->
        <div class="d-flex justify-content-between mb-2">
          <p class="m-0">LIMU ID</p>
          <span
            class="badge mb-2 fw-bold"
            :class="{
              'bg-soft-info text-info':
                research.status.name != 'Closed' &&
                research.status.name != 'Rejected' &&
                research.status.name != 'Suspended',
              'bg-soft-danger text-danger': research.status.name == 'Rejected',
              'bg-soft-success text-success': research.status.name == 'Closed',
              'bg-soft-warning text-warning':
                research.status.name == 'Suspended',
            }"
            >{{ research.limu_id }}</span
          >
        </div>
        <!-- <h6 class="text-muted">More info</h6> -->
        <div class="more-info">
          <p class="m-0">Actions</p>
          <span class="badge bg-light-gray text-black fs-8 rounded-pill">{{
            research.actions_count
          }}</span>
        </div>
        <div class="more-info my-2">
          <p class="m-0">Reports</p>
          <span class="badge bg-light-gray text-black fs-8 rounded-pill">{{
            research.reports_count
          }}</span>
        </div>
      </div>
      <!-- card footer -->
      <div class="research-footer border-top border-1 mt-2">
        <div class="d-flex justify-content-between my-2">
          <p class="m-0">Status</p>
          <span
            class="badge mt-1 fs-8 align-self-center fw-bold"
            :class="{
              'bg-primary':
                research.status.name != 'Closed' &&
                research.status.name != 'Rejected' &&
                research.status.name != 'Suspended',
              'bg-danger': research.status.name == 'Rejected',
              'bg-success': research.status.name == 'Closed',
              'bg-warning': research.status.name == 'Suspended',
            }"
            >{{ research.status.name }}</span
          >
        </div>
        <div class="mt-3">
          <small class="text-secondary fs-8 d-block"
            ><b>Created at</b>: {{ research.created_at }}</small
          >
          <small class="text-secondary fs-8 d-block"
            ><b>Last update</b>: {{ research.updated_at }}</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "researchCard",
  props: {
    research: {
      type: [Object, null],
      required: true,
    },
  },
  methods: {
    goToResearch(id) {
      const routeData = this.$router.resolve({
        name: "research",
        params: { Id: id },
      });
      window.open(routeData.href, "_blank");
    },
    showHideFullTitle(e) {
      e.currentTarget.classList.toggle("text-nowrap");
    },
  },
};
</script>

<template>
  <RRSheader />
  <div>
    <!-- page title -->
    <page-title :in_submission="in_submission" :refresh="fetchResearches">
      <template v-slot:titlePrefix>Recent </template>
      <template v-slot:mainTitle>Researches </template>
    </page-title>
    <!-- loading -->
    <div class="spinner" v-if="researchesList.length == 0 && in_submission">
      <div class="d-flex justify-content-center text-primary">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <!-- page content -->
    <div class="container-fluid">
      <!-- no research uploaded yet message -->
      <div
        class="row justify-content-center"
        v-if="researchesList.length == 0 && !in_submission"
      >
        <div class="col-auto text-center mt-5 pt-5">
          <img
            src="/img/oc-looking-for-answers.jpg"
            class="w-25"
            alt="no researches"
          />
          <p class="text-muted fw-bold fs-5 pt-5 text-center">
            You didn't have any research yet please upload a new reseach from
            the menu
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                fill="#8c98a4"
              ></path>
              <path
                d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="#8c98a4"
              ></path>
              <path
                opacity="0.3"
                d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="#2670b6"
              ></path>
            </svg>
          </p>
        </div>
      </div>
      <!-- researches list section -->
      <div class="row mt-5 p-5" v-if="researchesList.length > 0">
        <!-- review all researches button -->
        <div class="col-12 mb-2">
          <a
            class="text-decoration-underline float-end cursor-p"
            @click="$router.push({ name: 'researches-list' })"
            >Review full list
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                fill="#3182ce"
              ></path>
              <path
                opacity="0.3"
                d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                fill="#2670b6"
              ></path>
            </svg>
          </a>
        </div>
        <div
          class="col-sm-12 col-md-4 col-lg-3"
          v-for="research in researchesList"
          :key="research.id"
        >
          <!-- research card component-->
          <research-card :research="research" :key="research.id" />
        </div>
      </div>
    </div>
  </div>
  <popUpMessage />
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import pageTitle from "@/components/pageTitle";
import store from "@/store/index.js";
import RRSheader from "@/components/RRSheader";
import researchCard from "@/components/researchCard";

export default {
  components: { pageTitle, RRSheader, researchCard },
  name: "main",
  data() {
    return {
      researchesList: [],
      in_submission: false,
      broadCasting: null,
    };
  },
  methods: {
    async fetchResearches() {
      this.in_submission = true;
      await axiosConfig
        .post("api/fetchResearches")
        .then((res) => {
          this.researchesList = res.data.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
          return;
        });

      this.in_submission = false;
    },
  },
  async created() {
    this.fetchResearches();
    this.broadCasting = setInterval(() => {
      this.fetchResearches();
    }, 360000);
  },
  unmounted() {
    clearInterval(this.broadCasting);
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
}
</style>
